/**
 * Created by Denis Kuzin on 09 january 2025
 */

FogTileView.prototype.getGroundFogImage = function () {
    return this.getFogImage();
}

FogTileView.prototype.addFakeUnitNode = function () {

};

cleverapps.styles.FogTileView["garden"] = {
    anchorX: 0.5,
    anchorY: 0.5,

    groundStateOnly: true,
    noBorder: true,

    groundFog: {
        x: { align: "center" },
        y: { align: "center" }
    },

    borders: {
        center: {
            x: { align: "center", dx: 0 },
            y: { align: "center", dy: 0 },
            zOrder: 0
        }
    },

    disappear: {
        duration: 0.6,
        animation: "disappear",
        options: {
            dust: true
        }
    }
};