/**
 * Created by mac on 2/28/23
 */

var Merge2Scene = MainScene.extend({
    ctor: function (options) {
        options = options || {};

        if (!options.level) {
            options.level = new Level(0, 0);
        }

        this.styling = options.level ? GameSceneBase.ChooseStyling(options.level) : {};

        this._super(options);
    },

    onSceneLoaded: function () {
        cleverapps.environment.levelNo = this.level.levelNo;
        cleverapps.environment.episodeNo = this.level.episodeNo;
        cleverapps.environment.setScene(cleverapps.Environment.SCENE_MAIN);

        this.game = new Merge(this.level);

        this._super();

        this.createMap();

        var orangery = Map2d.currentMap.orangery;
        if (orangery && !cleverapps.gameModes.noControls && !cleverapps.config.wysiwygMode) {
            var orangeryView = new OrangeryView(orangery);
            this.addChild(orangeryView);

            cleverapps.focusManager.registerControl(orangery.getControlName(), new HidingNode(orangeryView, cleverapps.UI.VERTICAL));
        }
    },

    createMap: function () {
        this.map = new Map2dFlatView(this.game.map);
        this.addChild(this.map);
        this.updateSize();
    },


    playIntro: function (f, silent) {
        var actions = this.game.listIntroActions();

        if (silent) {
            actions = actions.map(function (action) {
                return function (f) {
                    action(f, silent);
                };
            });
        }

        this.game.introPlaying = true;

        cleverapps.focusManager.compound(function () {
            this.game.introPlaying = false;
            f();
        }.bind(this), actions);
    },

    getOverlappingNodes: function () {
        var items = [this.upMenuContainer];
        return items.filter(Boolean);
    },

    getAnimationsLayer: function () {
        return this.map.animations;
    },

    scaleGameField: function () {
        cleverapps.UI.inflateToBoundaries(this.map, this.getOverlappingNodes(), {
            maxScale: 1,
            padding: cleverapps.styles.Merge2Scene.padding
        });
    },

    getBackgroundStyles: function () {
        return NoneMainGameScene.prototype.getBackgroundStyles.apply(this, arguments);
    },

    listBundles: function () {
        var result = NoneMainGameScene.prototype.listBundles.apply(this, arguments);
        result.push(Map2d.getTilesTexture(this.level.meta.tiles));
        result.push(Map2d.getUnitsTexture(this.level.meta.units));
        result.push("quests_" + this.level.meta.units);
        result.push("unitstories_" + this.level.meta.expedition);
        return result;
    },

    updateSize: function () {
        this._super();

        if (this.map) {
            var bgSize = cleverapps.resolution.getBgSize();
            var safePadding = cleverapps.resolution.getSafePadding();
            this.map.setPositionRound(bgSize.width / 2 - safePadding.left, bgSize.height / 2 - safePadding.bottom);
        }
    },

    animateZoom: function (zoom, duration, f) {
        f();
    }
});

GameScene = Merge2Scene;

cleverapps.styles.Merge2Scene = {
    padding: {
        top: 50,
        bottom: 50,
        left: 50,
        right: 50
    }
};