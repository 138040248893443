/**
 * Created by anatoly on 21.01.2025
 */

UnitView.prototype.handleClick = function () {
    if (!this.isClickable()) {
        return false;
    }

    if (this.unit.handleClick()) {
        Map2d.mapEvent(Map2d.CLICK_UNIT, { affected: this.unit });
        return true;
    }

    this.unit.squeeze();
    cleverapps.audio.playSound(bundles.main.urls.click_effect);

    return true;
};

UnitView.prototype.merge = function () {
    var mapView = Map2d.currentMap.getMapView();
    this.replaceParentSamePlace(mapView.animations);

    this.runAction(new cc.Sequence(
        new cc.ScaleTo(0.2, 0.7).easing(cc.easeBackIn()),
        new cc.RemoveSelf()
    ));
};

UnitView.prototype.startDidMerged = function () {
    var styles = cleverapps.styles.UnitView;

    var mapView = Map2d.currentMap.getMapView();
    var json = cleverapps.skins.getSlot("merge_animation_json") || bundles.merge_animations.jsons.merge_animation_json;

    var animationBelow = new cleverapps.Spine(json);
    animationBelow.setPositionRound(styles.mergeAnimationBelow);
    animationBelow.setCompleteListenerRemove();
    animationBelow.setSafeToRemove();
    mapView.addTile(Map2d.LAYER_UNITS, this.unit.x, this.unit.y, animationBelow);

    animationBelow.runAction(new cc.Sequence(
        new cc.DelayTime(0.1),
        new cc.CallFunc(function () {
            animationBelow.setAnimation(0, "animation_below", false);
        })
    ));
};

UnitView.prototype.didMerged = function () {
    this.stopAllActions();
    this.removePath();

    var parent = this.getParent();
    this.replaceParentSamePlace(cleverapps.scenes.getMovingNode(this));

    this.setVisible(false)
    this.setScale(0.7)

    this.mergeAction = this.runAction(new cc.Sequence(
        new cc.DelayTime(0.25),
        new cc.Show(),
        new cc.ScaleTo(0.2, 1).easing(cc.easeBackOut(0.8))
    )).setFinalize(function () {
        this.replaceParentSamePlace(parent);
    }.bind(this));
};

UnitView.prototype.getSpawnAnimationCoef = function () {
    return 1 / 2;
}
