/**
 * Created by anatoly on 21.12.2024
 */

MergeComponent.prototype.listMergeAtPoint = function (x, y) {
    var map = Map2d.currentMap;
    var mergingUnit = map.getUnit(x, y);

    if (mergingUnit && map.isGround(x, y) && !map.getFog(x, y) && this.unit.isMergeable(mergingUnit, true)) {
        return [mergingUnit];
    }
};

MergeComponent.prototype.listSameItemsGroup = function (x, y) {
    var map = Map2d.currentMap;
    var mergingUnit = map.getUnit(x, y);

    if (map.compareEqual(this.unit, x, y)) {
        return [mergingUnit];
    }
};

MergeComponent.prototype.mergeBonus = function () {
    return {
        bonus: 1,
        keepLast: 0,
        next: Infinity
    };
};