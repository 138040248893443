/**
 * Created by Denis Kuzin on 15 january 2025
 */

Unit.prototype.isBlockedCell = function (x, y) {
    var map = Map2d.currentMap;

    var unit = map.getUnit(x, y);
    if (unit && !this.isMergeable(unit)) {
        return true;
    }

    if (map.getFog(x, y) !== undefined) {
        return true;
    }

    return false;
};