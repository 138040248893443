/**
 * Created by Denis Kuzin on 20 january 2025
 */

CustomerRecipe.createRecipeTemplate = function () {
    var availableFamilies = [];

    cleverapps.unitsLibrary.listCodesByType("resource").forEach(function (code) {
        var list = cleverapps.unitsLibrary.listOpened(code);
        if (list.length) {
            availableFamilies.push(list);
        }
    });

    var selectedFamilies = cleverapps.Random.chooseAmount(availableFamilies, 1);
    var selectedUnits = [];

    for (var i = 0; i < selectedFamilies.length; i++) {
        var familyUnits = selectedFamilies[i];
        var unitToAdd = {};
        var randomIndex = Math.random();

        if (randomIndex < 0.7) {
            var lastIndex = familyUnits.length - 1;
            var stageIndex = lastIndex;

            if (lastIndex > 0 && Math.random() < 0.5) {
                stageIndex = lastIndex - 1;
            }

            unitToAdd = {
                code: familyUnits[stageIndex].code,
                stage: familyUnits[stageIndex].stage
            };
        } else if (randomIndex < 0.9) {
            if (familyUnits.length >= 3) {
                unitToAdd = {
                    code: familyUnits[familyUnits.length - 3].code,
                    stage: familyUnits[familyUnits.length - 3].stage
                };
            }
        } else if (familyUnits.length >= 4) {
            unitToAdd = {
                code: familyUnits[familyUnits.length - 4].code,
                stage: familyUnits[familyUnits.length - 4].stage
            };
        }

        if (!unitToAdd.code) {
            unitToAdd = {
                code: familyUnits[0].code,
                stage: familyUnits[0].stage
            };
        }

        selectedUnits.push(unitToAdd);
    }

    var totalStage = 0;
    selectedUnits.forEach(function (unit) {
        totalStage += unit.stage;
    });

    return {
        ingredients: selectedUnits,
        order: [{ reward: Math.round(totalStage / 3) }]
    };
};
