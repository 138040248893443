/**
 * Created by anatoly on 13.01.2025
 */

Mineable.prototype.handleNoSpaceOnStart = function () {
    if (!Map2d.currentMap.countEmptySlots()) {
        cleverapps.centerHint.createTextHint("Spawn.nospace");
        return true;
    }

    return false
};