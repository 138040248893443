/**
 * Created by anatoly on 20.01.2025
 */

FamiliesHelper.Merge2GeneratorFamily = function (options) {
    return {
        type: "generator",
        resource: options.resource,
        units: [{
            important: true,
            unmergeable: true,
            mineable: [{
                time: "0 second",
                energy: 1,
                endless: true
            }]
        }]
    };
};

if (typeof cc === "undefined") {
    module.exports = FamiliesHelper;
}