/**
 * Created by Denis Kuzin on 24 december 2024
 */

Families.initialize = function () {
    Families.initializeBase();

    Object.keys(Families).filter(function (code) {
        return typeof Families[code] !== "function";
    }).forEach(function (code) {
        delete Families[code];
    });

    Families.wood = FamiliesHelper.HeartsResourceFamily(7);
    Families.stone = FamiliesHelper.HeartsResourceFamily(5);
    Families.bamboo = FamiliesHelper.HeartsResourceFamily(6);
    Families.wagon = FamiliesHelper.HeartsResourceFamily(8);

    Families.gardengenerator1 = FamiliesHelper.Merge2GeneratorFamily({ resource: ["wood", "stone"] });

    Families.afterInitialize();
};

if (typeof cc === "undefined") {
    module.exports = Families;
}
