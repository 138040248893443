/**
 * Created by olga on 23.01.2025
 */

var Merge2BaseTutorial = function () {
    return new TutorialScenario("merge2_base_tutorial", {
        listSteps: Merge2BaseTutorial.listSteps,
        isAvailable: function () {
            return false;
        }
    });
};

Merge2BaseTutorial.listSteps = function () {
    return Merge2BaseTutorial.STEPS.map(function(step) {
        return Merge2BaseTutorial.createStep(step);
    });
};

Merge2BaseTutorial.createStep = function (data) {
    var scene = cleverapps.scenes.getRunningScene();
    var force =  {
        text: data.text || ""
    }
    var options = {
        name: data.name,
        onStart: function () {
            var amount = data.name === "merge" ? 2 : 1;
            this.units = Map2d.currentMap.listAvailableUnits(data.unit).slice(0, amount);
             var unitViews = this.units.map(function(unit) {
                 return unit.onGetView();
             });

            if (this.units[0].isGrounded()) {
                unitViews = unitViews.reverse();
            }

            force.finger = function() {
                return {
                    path: unitViews
                }
            }.bind(this);

            force.position = function() {
                var mapRect = scene.map.getGlobalBoundingBox();
                return {  x: mapRect.x + mapRect.width / 2,
                    y: mapRect.y + mapRect.height
                }
            }

            this.force = cleverapps.forces.create(scene.map, force);

            Game.currentGame.map.on("changeDragging", function(unit){
                if (unit) {
                    this.force.stopFinger();
                } else {
                    this.force.resumeFinger();
                }
            }.bind(this), this);
        },

        onExecution: function () {
            this.force.hideForceHints();
        },

        onFinish: function () {
            if (this.force && cleverapps.forces.getRunningForce() === this.force) {
                cleverapps.forces.closeRunningForce();
            }
        }
    };

    return new TutorialStep(options);
};


Merge2BaseTutorial.STEPS = [
    {
        name: "merge",
        unit: {code: "wood", stage: 0},
        text: "Merge2.BaseTutorial0"
    },
    {
        name: "merge",
        unit: {code: "wood", stage: 1},
        text: "Merge2.BaseTutorial1"
    },
    {
        name: "merge",
        unit: {code: "stone", stage: 0},
        text: "Merge2.BaseTutorial2"
    },
    {
        name: "mine",
        unit: {code: "gardengenerator1", stage: 0},
        text: "Merge2.BaseTutorial3"
    }
]

