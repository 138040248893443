/**
 * Created by andrey on 07.02.2022.
 */

FogsConfig.initialize = cleverapps.extendFunc(FogsConfig.initialize, function () {
    this._super();

    FogsConfig.main = {
        fog0: {
            id: 0,
            price: 0,
            exp: 0,
            headless: true
        }
    };

    FogsConfig.afterInitialize();
});

FogsConfig.initialize();
