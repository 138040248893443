/**
 * Created by Denis Kuzin on 13 january 2025
 */

var GroundedView = cc.Node.extend({
    ctor: function (grounded, unitView) {
        this._super();

        var type = Map2d.currentMap.getTerrainType(grounded.unit.x, grounded.unit.y);
        var fogType = (bundles[type.name] || bundles[type.defaultTiles]).meta.fog;
        var icon = new cc.Sprite(bundles["fogs_" + fogType].frames["ground_fog_png_" + fogType]);
        icon.setPositionRound({ align: "center" }, { align: "center" });
        unitView.addChild(icon);
        unitView.setImageOpacity(160);
    },

    onRemove: function (silent) {

    },

    highlight: function () {

    },

    unhighlight: function () {

    }
});
