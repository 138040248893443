/**
 * Created by Denis Kuzin on 23 december 2024
 */

var Map2dFlatView = cc.Node.extend({
    ctor: function (map2d) {
        this._super();

        this.map2d = map2d;

        var totalWidth = map2d.getWidth() * cleverapps.styles.Map2dFlatView.cell.width;
        var totalHeight = map2d.getHeight() * cleverapps.styles.Map2dFlatView.cell.height;

        this.setContentSize2(totalWidth, totalHeight);
        this.setAnchorPoint(0.5, 0.5);

        var animations = this.animations = new cc.Node();
        animations.setContentSize(this.getContentSize());
        animations.setPositionRound(this.width / 2, this.height / 2);
        animations.setAnchorPoint(0.5, 0.5);
        animations.debugId = "Animations";
        this.addChild(animations, 1);

        if (cleverapps.config.debugMode) {
            animations._debugExclude = true;
        }

        map2d.onAdd = this.createListener(function (layer, x, y, object) {
            if (layer === Map2d.LAYER_FOG && object instanceof FogTile) {
                this.addToLayer(layer, x, y);
            }
        }.bind(this));
        map2d.onAddUnit = this.createListener(this.addToLayer.bind(this, Map2d.LAYER_UNITS));
        map2d.onDiscoverMapView = function () {
            return this;
        }.bind(this);

        for (var y = 0; y < this.map2d.getHeight(); y++) {
            for (var x = 0; x < this.map2d.getWidth(); x++) {
                var layers = [Map2d.LAYER_GROUND, Map2d.LAYER_FOG, Map2d.LAYER_UNITS];

                for (var i = 0; i < layers.length; i++) {
                    var layer = layers[i];
                    this.addToLayer(layer, x, y);
                }
            }
        }

        this.addControls();
    },

    addControls: function () {
        var controlsNode = new Map2dControls(this);
        controlsNode.debugId = "Map2dFlatView";
        this.addChild(controlsNode, -1);
    },

    findTouchUnitOrDecorator: function (touch) {
        var cell = this.getCellByTouch(touch);

        if (cell) {
            var activeScenario = cleverapps.tutorial.getActive();
            var tutorialUnits = activeScenario && activeScenario.getCurrentStep().units;

            if (tutorialUnits && !tutorialUnits.some(function(unit) {
                return cell.x === unit.x && cell.y === unit.y;
            })) {
                return;
            }

            var unit = Map2d.currentMap.getUnitWithHead(cell.x, cell.y);

            if (unit && unit.checkTouchInside(touch)) {
                return unit;
            }
        }
    },

    findTouchFog: function () {

    },

    getCellByTouch: function (touch) {
        var pos = this.convertTouchToNodeSpace(touch);
        return this.getCellByCoordinates(pos);
    },

    getCellByCoordinates: function (p) {
        var zero = this.alignInGrid(0, 0);
        var x = (p.x - zero.x) / cleverapps.styles.Map2dFlatView.cell.width;
        var y = (p.y - zero.y) / cleverapps.styles.Map2dFlatView.cell.height;
        return cc.p(Math.round(x), Math.round(y));
    },

    addToLayer: function (layer, x, y) {
        var object = this.map2d.getValue(layer, x, y);
        if (object) {
            var view;

            switch (layer) {
                case Map2d.LAYER_UNITS:
                    view = new UnitView(object);
                    view.sprite._debugExclude = true;
                    break;
                case Map2d.LAYER_GROUND:
                    view = ViewReader.parse(object, this.map2d);
                    break;
                case Map2d.LAYER_FOG:
                    view = new FogTileView(object);
                    break;
            }

            if (!view) {
                return;
            }

            this.addTile(layer, x, y, view)
        }
    },

    upAdditionalViewAboveClouds: function () {

    },

    onCellClick: function () {

    },

    containsView: function (unitView) {
        return unitView.getParent() === this;
    }
});

Map2dFlatView.prototype.toScreen = function (x, y) {
    return cc.p(x * cleverapps.styles.Map2dFlatView.cell.width, y * cleverapps.styles.Map2dFlatView.cell.height);
};

Map2dFlatView.prototype.alignInGrid = function (x, y) {
    return cc.p(
        x * cleverapps.styles.Map2dFlatView.cell.width + cleverapps.styles.Map2dFlatView.cell.width / 2,
        y * cleverapps.styles.Map2dFlatView.cell.height + cleverapps.styles.Map2dFlatView.cell.height / 2
    );
};

Map2dFlatView.prototype.alignViewInGrid = function (x, y, view) {
    var position = this.alignInGrid(x, y);
    view.setPositionRound(position.x, position.y);
};

Map2dFlatView.prototype.alignPositionInGrid = function (x, y, position) {
    var cellPosition = this.alignInGrid(x, y);
    return cc.p(position.x + cellPosition.x, position.y + cellPosition.y);
};

Map2dFlatView.prototype.getSourceCenterPosition = function (source) {
    return this.alignInGrid(source.x, source.y);
};

Map2dFlatView.prototype.addTile = function (layer, x, y, view) {
    var position = this.alignInGrid(x, y);
    view.setPositionRound(position.x, position.y);
    if (view.parent) {
        view.replaceParentSamePlace(this);
        return;
    }

    this.addChild(view);
};

Map2dFlatView.prototype.getUnitCenterPos = function (x, y) {
    return this.alignInGrid(x, y);
}

cleverapps.styles.Map2dFlatView = {
    cell: {
        width: 148,
        height: 148
    }
};
