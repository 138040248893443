/**
 * Created by Andrey Popov on 12/5/24.
 */

GeneratorSourceMine.prize = function (level, unit) {
    var probabilities = [];
    var units = [];
    unit.getResource().forEach(function (resource) {
        probabilities.push(1 / unit.getResource().length);
        units.push({code: resource, stage: 0})
    });

    return [{
        amount: 1,
        probabilities: probabilities,
        units: units
    }];
};

Mines.generator = GeneratorSourceMine.prize;